import { Link } from '@chakra-ui/next-js';
import { Flex } from '@chakra-ui/react';
import { navLinks } from '@/shared/constants';
import ProductsMenu from './ProductsMenu';

const DesktopMenu = () => (
  <Flex
    alignItems='center'
    as='nav'
    display={{ base: 'none', lg: 'flex' }}
    gap={{ base: 'md', lg: '2xl' }}
  >
    {navLinks.map(({ title, items, href }) => {
      if (items) {
        return null;
      }

      return (
        <Link
          key={title}
          href={href!}
        >
          {title}
        </Link>
      );
    })}

    <ProductsMenu />
  </Flex>
);

export default DesktopMenu;
