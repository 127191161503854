import { Link } from '@chakra-ui/next-js';
import { Button, Heading, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { navLinks } from '@/shared/constants';
import { ArrowDown } from '@/shared/ui';
import { localStorage } from '@/shared/utils';

const productsLinks = navLinks.find(({ title }) => title === 'Products');

const ProductsMenu = () => {
  const isShowProducts = localStorage.getItem('isShowProducts') !== 'false';

  if (!isShowProducts) {
    return null;
  }

  return (
    <Menu
      autoSelect={false}
      placement='top'
    >
      {({ isOpen }) => (
        <>
          <MenuButton
            alignItems='center'
            as={Button}
            display='flex'
            fontWeight='normal'
            rightIcon={
              <ArrowDown
                boxSize={4}
                transform={isOpen ? 'rotate(0.5turn)' : 'rotate(1turn)'}
                transition='0.3s'
              />
            }
            variant='unstyled'
          >
            Products
          </MenuButton>
          <MenuList
            bg='bg.light'
            border='none'
            display='flex'
            gap='sm'
            p='md'
            rootProps={{
              maxW: '36rem',
              transform: 'translateX(-50%) !important',
              inset: '3rem 0 0 50% !important',
            }}
          >
            {productsLinks?.items!.map(({ name, description, Icon, href }) => (
              <MenuItem
                key={name}
                alignItems='start'
                as={Link}
                borderRadius='md'
                flexDir='column'
                gap='md'
                href={href}
                p='sm'
                w={{ base: '14rem', lg: '16rem' }}
              >
                <Icon
                  boxSize={8}
                  color='primary.pure'
                  strokeWidth='1.5'
                />
                <Heading
                  fontWeight='semibold'
                  size='md'
                >
                  {name}
                </Heading>
                <Text color='text.medium'>{description}</Text>
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default ProductsMenu;
